<template>
	<div>
    <br>
		<div class="alert alert-danger">An unknown error occurred.</div>
		<div>
			Please report this tracking number, which makes it possible to locate your session in the logs to the
			system administrator.
		</div>
		<div class="d-flex justify-content-center p-4">
			<span class="float border rounded bg-light text-center p-2">{{ trackid }}</span>
		</div>
	</div>
</template>

<script>
	export default {
		name : 'ServerError',
		data : () => {
			return {
				trackid : 'unknown'
			}
		},
		created() {
			let tie = document.getElementById('trackid')
			tie && (this.trackid = tie.innerText)
		}
	}
</script>