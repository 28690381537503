<template>
	<layout-user>
	<form class="container" @submit.prevent="verifyCode">
		<div class="col-xs-12 pb-3">
      <div class="mb-1 d-flex justify-content-center align-items-center">
        Two Factor Status:
        <span class="mr-2 ml-2" v-show="!this.$profile.hasMultiFactor">NOT</span> ENROLLED
      </div>
      <div class="mb-1" >
        <router-link to="/2fa/setup" class="btn btn-block btn-secondary">
          <span v-if="this.$profile.hasMultiFactor">Manage</span>
          <span v-else>Set Up</span>
            Two Factor Authentication
        </router-link>
      </div>
      <div class="mb-1">
        <router-link to="/change-password" class="btn btn-block btn-secondary">Manage Password</router-link>
      </div>
      <div class="mb-1 mt-2">
        <div class="d-flex justify-content-center align-items-center">Quick Links</div>
        <div id="apps" class="card-deck mt-1 mb-3">
          <b-card v-for="app in $profile.appLinks">
            <b-card-text class="text-center">
              <a :href="app.link" class="stretched-link">
                <i class="fab fa-3x" :class="app.icon"></i>
                <div>{{ app.title }}</div>
              </a>
            </b-card-text>
          </b-card>
        </div>
      </div>
      <div class="mb-3 mt-2">
          <a class="btn btn-block btn-westmont" accesskey="l" value="Sign Out" tabindex="4"
             :disabled="submitted"
             :href="this.$saml_urls.logout">
            <b-spinner small v-show="submitted"></b-spinner>
            Sign Out
          </a>
      </div>
		</div>
	</form>
	</layout-user>
</template>
<script>
import LayoutUser from "@/layouts/user.vue"
	export default {
		name : 'Profile',
		components: {
			LayoutUser
		},
		data : () => {
			return {
				submitted: false,
				password : {
					old  : '',
					new1 : '',
					new2 : ''
				},
			}
		}
	}
</script>
<style lang="scss">
	#apps.card-deck .card-body {
		padding: .5rem 0;
	}
</style>