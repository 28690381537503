<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<script>
export default {
	name: "App"
}
</script>
<style lang="scss">
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	width: 100%;
}

#header-logo {
	fill: #fff;
	height: 30px;
}

#nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}
#login-portal {
	padding:0;
}
.badges > .badge {
	margin: 0 2px;
}
.text-maroon {
	color: rgba(163,30,55);
}
.navbar-westmont {
	background-color: rgba(163,30,55,.9);
	color: #fff;
	a.nav-link {
		color: #fff !important;
	}
	.nav-item:hover {
		a.nav-link {
			font-weight: bold;

			text-decoration: none;
		}
	}

}
</style>
